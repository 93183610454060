import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                <div className="col-md-12">
                    <div className="about-desc">
                        <span className="heading-meta">About Me</span>
                        <h2 className="colorlib-heading">Who Am I?</h2>
                        <p>I'm Ryan, a senior software engineer at ThousandEyes (part of Cisco) with a passion for democratizing tech.</p>
                        <p>My current work involves the Enterprise Agent at ThousandEyes. This software is designed to be a turn-key solution, which makes it simple for users to understand and react to network issues no matter where they are. This is increasingly important as companies transition away from traditional on-premise servers to the cloud (where visibility would normally be limited).</p>
                        <p>In my previous role, I worked at Datto, where I developed software to backup and secure small and medium businesses. Before that, at PerkinElmer, I created a proof of concept to transition the traditional ICP-OES instrument platform to a cloud-based design. I also worked at Thermo Fisher Scientific where I worked on genetic sequencing instruments. The goal was to take a multiday workflow that involved multiple instruments down to one single 8-hour workflow using a single machine.</p>
                        <p>I believe that simplicity is key when designing software for my customers.</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">What I do</span>
                <h2 className="colorlib-heading">Skills & Expertise</h2>
            </div>
            </div>
            <div className="row row-pt-md">
            <div className="col-md-4 text-center animate-box">
                <div className="services color-1">
                <span className="icon">
                    <i className="icon-bulb" />
                </span>
                <div className="desc">
                    <h3>Backend Development</h3>
                    <p>I have experience developing backend applications in C++, Python, Java, and PHP.</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>Data Structures & Algorithms</h3>
                    <p>My background in computer science provides me with a detailed understanding of the fundamentals.</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-5">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                    <h3>Cloud Development</h3>
                    <p>Experience developing applications for the cloud including technologies like Docker and Kubernetes.</p>
                </div>
                </div>
            </div>
            {/*
            <div className="col-md-4 text-center animate-box">
                <div className="services color-2">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                    <h3>Dev Ops</h3>
                    <p>Jenkins , Kubernetes , Docker </p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-4">
                <span className="icon">
                    <i className="icon-layers2" />
                </span>
                <div className="desc">
                    <h3>Graphic Design</h3>
                    <p>My friend knows .. P</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-6">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>Digital Marketing</h3>
                    <p>I use Instagram eight hours a day :) </p>
                </div>
                </div>
            </div>
            */}
            </div>
        </div>
      </div>
    )
  }
}
